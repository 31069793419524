import { useState, useRef, useEffect } from 'react';
import logo from "../../assets/magbotlogo.png";
import "../../styles/nav.css";

const Navbar = ({ scrollToPricing, scrollToContact, activeSection }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null); // Ref for the mobile menu

    // Toggle the mobile menu
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prev) => !prev); // Toggle menu state
    };

    // Close mobile menu if clicked outside
    const handleClickOutside = (event) => {
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            setIsMobileMenuOpen(false);
        }
    };

    // Add event listener for clicks outside the mobile menu
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handlePricingClick = () => {
        scrollToPricing(); // Call the scroll function
        setIsMobileMenuOpen(false); // Close the menu
    };

    return (
        <div className="w-full bg-[#FBF7FF] fixed h-[80px] top-0 items-center drop-shadow-down-blur pl-10 pr-10 z-50">
            <div className="flex justify-between items-center h-full px-5 font-inter">
                <div className="flex items-center gap-16">
                    <img src={logo} alt="Logo" className="w-[80px] sm:w-[100px] " />
                    <div className="hidden md:flex gap-10 cursor-pointer">
                        {/* <div
                            onClick={handlePricingClick} // Use new handler
                            className={`text-[#000000] text-[14px] ${activeSection === 'price' ? 'underline underline-offset-[8px] font-bold' : ''}`}
                        >
                            Pricing
                        </div> */}
                        {/* <div
                            onClick={() => {
                                scrollToContact();
                                setIsMobileMenuOpen(false); // Close the menu
                            }}
                            className={`text-[#000000] text-[14px] ${activeSection === 'contact' ? 'underline underline-offset-[8px] font-bold' : ''}`}
                        >
                            Contact us
                        </div> */}
                    </div>
                </div>
                <button className="hidden md:flex demo-btn items-center bg-[#5C24FC] py-[8px] px-[16px] gap-[4px] rounded-[8px] text-white text-[14px] font-medium border-[#9D7AFF00] border-opacity-5"
                    onClick={() => {
                        scrollToContact();
                        setIsMobileMenuOpen(false); // Close the menu
                    }}  
                >
                    Schedule a demo
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.4185 9.38722L6.94972 14.856C6.89891 14.9068 6.83859 14.9471 6.7722 14.9746C6.70581 15.0021 6.63466 15.0162 6.5628 15.0162C6.49095 15.0162 6.41979 15.0021 6.35341 14.9746C6.28702 14.9471 6.2267 14.9068 6.17589 14.856C6.12508 14.8052 6.08477 14.7448 6.05728 14.6785C6.02978 14.6121 6.01562 14.5409 6.01562 14.4691C6.01562 14.3972 6.02978 14.326 6.05728 14.2597C6.08477 14.1933 6.12508 14.133 6.17589 14.0821L11.2584 9.0003L6.17589 3.91847C6.07327 3.81585 6.01562 3.67667 6.01562 3.53155C6.01563 3.38643 6.07327 3.24726 6.17589 3.14464C6.27851 3.04202 6.41768 2.98438 6.5628 2.98438C6.70792 2.98437 6.8471 3.04202 6.94972 3.14464L12.4185 8.61339C12.4693 8.66418 12.5097 8.72449 12.5372 8.79088C12.5647 8.85727 12.5789 8.92844 12.5789 9.0003C12.5789 9.07217 12.5647 9.14333 12.5372 9.20972C12.5097 9.27611 12.4693 9.33643 12.4185 9.38722Z"
                            fill="white"
                        />
                    </svg>
                </button>
                {/* Mobile menu button */}
                <div className="md:hidden flex items-center">
                    <button onClick={toggleMobileMenu} className="text-black">
                        <svg width="26px" height="26px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 6C5.34315 6 4 7.34315 4 9H20C20 7.34315 18.6569 6 17 6H7Z" fill="#000000" />
                            <path d="M7 18C5.34315 18 4 16.6569 4 15H20C20 16.6569 18.6569 18 17 18H7Z" fill="#000000" />
                            <path d="M3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H3Z" fill="#000000" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile menu */}
            {isMobileMenuOpen && (
                <div ref={mobileMenuRef} className="md:hidden bg-[#FBF7FF] absolute top-[80px] left-0 w-full px-5 py-4 drop-shadow-lg z-40">
                    <div className="flex flex-col gap-4 pl-10">
                        <div onClick={handlePricingClick} className="text-[#000000] text-[14px]">Pricing</div>
                        <div onClick={() => {
                            scrollToContact();
                            setIsMobileMenuOpen(false); // Close the menu
                        }} className="text-[#000000] text-[14px]">Contact us</div>
                        <button className="demo-btn flex items-center bg-[#5C24FC] py-[12px] px-[16px] gap-[4px] rounded-[8px] text-white text-[14px] w-[56%] font-medium">
                            Schedule a demo
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.4185 9.38722L6.94972 14.856C6.89891 14.9068 6.83859 14.9471 6.7722 14.9746C6.70581 15.0021 6.63466 15.0162 6.5628 15.0162C6.49095 15.0162 6.41979 15.0021 6.35341 14.9746C6.28702 14.9471 6.2267 14.9068 6.17589 14.856C6.12508 14.8052 6.08477 14.7448 6.05728 14.6785C6.02978 14.6121 6.01562 14.5409 6.01562 14.4691C6.01562 14.3972 6.02978 14.326 6.05728 14.2597C6.08477 14.1933 6.12508 14.133 6.17589 14.0821L11.2584 9.0003L6.17589 3.91847C6.07327 3.81585 6.01562 3.67667 6.01562 3.53155C6.01563 3.38643 6.07327 3.24726 6.17589 3.14464C6.27851 3.04202 6.41768 2.98438 6.5628 2.98438C6.70792 2.98437 6.8471 3.04202 6.94972 3.14464L12.4185 8.61339C12.4693 8.66418 12.5097 8.72449 12.5372 8.79088C12.5647 8.85727 12.5789 8.92844 12.5789 9.0003C12.5789 9.07217 12.5647 9.14333 12.5372 9.20972C12.5097 9.27611 12.4693 9.33643 12.4185 9.38722Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
