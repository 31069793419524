import "../../styles/demo.css";
import gradient from '../../assets/demogradient.png';

const Demo = ({scrollToContact,activesection}) => {
    return (
        <div className="bg-[#E9E6FD80] px-4 sm:px-8 md:px-20 pt-10 pb-10  4k:px-[25%] max-w-screen-4k mx-auto relative">
            {/* Content Container */}
            <div className="flex items-center justify-center relative z-10">
                {/* Background Gradient Image */}
                {/* <img src={gradient} alt="" className="absolute top-0 left-0 w-full h-full object-cover z-10 opacity-40" /> */}
                
                <div className="bg-[#7C50DD] font-inter  w-full max-w-[1200px] text-white h-auto rounded-[20px] flex items-center justify-center p-6">
                    <div className="block text-center">
                        <p className="text-[28px] sm:text-[36px] w-full md:w-[80%] mx-auto mb-8">
                            Leverage self-learning, autonomous AI agents to deliver automated customer experiences.
                        </p>
                        <button className="Demo-btn text-black text-[14px] sm:text-[16px] font-[400] leading-[22px] px-6 py-3 rounded-[8px] transition-all bg-[#FFFFFF]"
                            onClick={() => scrollToContact()}
                        >
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
            
            {/* SVG Background */}
            {/* <svg className="absolute max-w-[50%] top-0 right-0 w-full h-full z-0" width="1712" height="927" viewBox="0 0 1712 927" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_3801_2899)">
                    <mask id="path-1-inside-1_3801_2899" fill="white">
                        <path d="M1448.01 463.991C1447.9 573.777 1182.87 662.522 856.049 662.208C529.226 661.894 264.369 572.64 264.474 462.854C264.58 353.068 529.608 264.323 856.431 264.637C1183.25 264.951 1448.11 354.205 1448.01 463.991ZM409.575 462.993C409.495 545.86 609.41 613.229 856.096 613.466C1102.78 613.702 1302.83 546.718 1302.9 463.851C1302.98 380.985 1103.07 313.616 856.384 313.379C609.698 313.142 409.655 380.126 409.575 462.993Z" />
                    </mask>
                    <path d="M1448.01 463.991C1447.9 573.777 1182.87 662.522 856.049 662.208C529.226 661.894 264.369 572.64 264.474 462.854C264.58 353.068 529.608 264.323 856.431 264.637C1183.25 264.951 1448.11 354.205 1448.01 463.991ZM409.575 462.993C409.495 545.86 609.41 613.229 856.096 613.466C1102.78 613.702 1302.83 546.718 1302.9 463.851C1302.98 380.985 1103.07 313.616 856.384 313.379C609.698 313.142 409.655 380.126 409.575 462.993Z" fill="#9C39FF" fill-opacity="0.8" />
                    <path d="M1448.01 463.991C1447.9 573.777 1182.87 662.522 856.049 662.208C529.226 661.894 264.369 572.64 264.474 462.854C264.58 353.068 529.608 264.323 856.431 264.637C1183.25 264.951 1448.11 354.205 1448.01 463.991ZM409.575 462.993C409.495 545.86 609.41 613.229 856.096 613.466C1102.78 613.702 1302.83 546.718 1302.9 463.851C1302.98 380.985 1103.07 313.616 856.384 313.379C609.698 313.142 409.655 380.126 409.575 462.993Z" stroke="white" stroke-width="2" mask="url(#path-1-inside-1_3801_2899)" />
                </g>
                <defs>
                    <filter id="filter0_f_3801_2899" x="0.473602" y="0.635834" width="1711.53" height="925.573" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="132" result="effect1_foregroundBlur_3801_2899" />
                    </filter>
                </defs>
            </svg> */}
        </div>
    );
}

export default Demo;
