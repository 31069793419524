import "../../styles/industries.css";
import astro from '../../assets/astrabento.png';
import starvector from '../../assets/startvector.svg';
import astraweb from '../../assets/astraweb.png';

const Industries = () => {
    return (
        <>
            <div className="flex justify-center items-start mt-12 md:mt-[6%] 4k:mt-[16%] mx-auto relative">
                <div className="block">
                    <button className="tag-title font-[600] text-[16px] font-inter">Plug and Play</button>
                </div>
            </div>
            <div className="mt-5 block">
                <h1 className="text-center font-[500] font-inter text-[28px] md:text-[60px]  leading-[72px]"> {/* Responsive text size */}
                    Set up your MagBot today
                </h1>
                <p className="mt-1 text-center font-[400] font-inter text-[#0E051B] leading-[40px] text-[16px] md:text-[18px]"> {/* Responsive text size */}
                    Conversational ecommerce: Redefining Retail with Bots
                </p>
            </div>

            <div className="flex justify-center mt-5">
                <div className="mt-5 flex flex-col md:flex-row rounded-[20px] w-[95%] md:w-[97%] lg:w-[80%] xl:w-[60%] 4k:w-[98%]   p-[20px] gap-[20px] md:gap-[60px]  bg-[#E9E6FD80] bg-opacity-[50%]">
                    <img src={astro} alt="" className="w-full md:w-[396px] h-[396px] object-cover" /> {/* Responsive image size */}
                    <div className="block">
                        <h2 className="font-[600] leading-[63px] font-inter text-[32px] md:text-[52px]"> {/* Responsive text size */}
                            Effortless Integration with Shopify
                        </h2>
                        <p className="mt-5 font-[400] font-inter text-[14px] text-[#0E051B] md:text-[18px] w-full md:w-[95%] text-left">
                            MagBot.ai integrates with your Shopify store in just 5 minutes, bringing powerful automation and customer support to your store instantly. No complex setup—just a smooth, efficient experience from day one.
                        </p>

                        <ul className="block mt-10">
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Fast 5-minute setup with Shopify’s platform.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Automates tasks like product recommendations, cart recovery, and order tracking.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Syncs seamlessly with your product catalog and inventory.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Fully customizable to fit your store's unique needs.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Minimal maintenance, maximizing uptime and performance.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Second Box */}
            <div className="flex justify-center mt-10">
                <div className="mt-5 flex flex-col md:flex-row rounded-[20px] w-[95%] md:w-[97%] lg:w-[80%] xl:w-[60%] 4k:w-[98%] p-[20px] gap-[20px] md:gap-[60px] bg-[#E9E6FD80] bg-opacity-[50%]">
                    <div className="block">
                        <h2 className="font-[600] leading-[63px]  w-[102%] font-inter text-[32px] md:text-[52px]"> {/* Responsive text size */}
                            Universal Compatibility for Any Website
                        </h2>
                        <p className="mt-5 font-[400] font-inter text-[14px] text-[#0E051B] md:text-[18px] w-full md:w-[95%] text-left">
                            MagBot.ai is designed to integrate seamlessly with any website, giving you access to powerful automation and customer support, no matter what platform you use. Experience hassle-free integration and start enhancing your store’s efficiency and sales in minutes.
                        </p>

                        <ul className="block mt-10">
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Easy integration with any website.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Supports essential eCommerce functions like product guidance, order tracking, and FAQ automation</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Fully customizable features to match your brand and customer needs.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Works 24/7 to help, convert, and retain customers across all platforms.</p>
                            </li>
                            <li className="flex gap-3 mt-5">
                                <img src={starvector} alt="" />
                                <p className="font-[400] text-[18px] font-inter leading-[32px] text-[#0E051B]"> Minimal maintenance for consistent, reliable performance.</p>
                            </li>
                        </ul>
                    </div>
                    <img src={astraweb} alt="" className="w-full md:w-[396px] h-[396px] object-cover" /> {/* Responsive image size */}
                </div>
            </div>
        </>
    );
}

export default Industries;
