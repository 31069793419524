import SolutionCard from "./solutioncards";

const Solutions = () => {
    return (
        <div className="mt-16 bg-[#E9E6FD80] bg-opacity-[50%] px-4 sm:px-8 md:px-20 4k:px-[25%] lg:px-[19%] lg:w-[100%] md:py-20 pt-10 pb-10   max-w-screen-4k mx-auto"> {/* Responsive padding */}
            <div className="flex justify-center items-start relative">
                <div className="block">
                    <button className="tag-title font-[600] leading-[22px] font-inter text-[16px]">We're Unique</button>
                </div>
            </div>
            <div className="mt-2 block">
                <h1 className="text-center font-[500] w-[100%] leading-[72px] text-[32px] sm:text-[38px] md:text-[60px]"> {/* Responsive text sizes */}
                    Advanced AI-Powered Capabilities for Enhanced Customer Interaction
                </h1>
                <p className="mt-2 text-center font-[400] font-inter leading-[40px] text-[16px] sm:text-[18px]"> {/* Responsive text sizes */}
                    MagBot.ai harnesses cutting-edge AI technologies to deliver accurate, context-aware responses and continuously improve customer interactions. From understanding intent to refining responses with user feedback, MagBot.ai ensures seamless, human-like communication tailored to eCommerce needs
                </p>
            </div>
            <SolutionCard />
        </div>
    );
}

export default Solutions;
