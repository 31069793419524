import "../../styles/solutioncard.css";
import reinforcementlearning from "../../assets/card-icon/reinforcement-learning.png";
import artificialintelligence from "../../assets/card-icon/artificial-intelligence.png";
import search from "../../assets/card-icon/search.png";
import nlp from "../../assets/card-icon/setting.png";
import socialnetwork from "../../assets/card-icon/social-network.png";
import listpaper from "../../assets/card-icon/listpaper.png";

const SolutionCard = () => {
    return (
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-4"> {/* Added padding for centering on small screens */}
            <div className="bg-white font-inter pt-8 pb-8 pr-10 pl-10 rounded-[20px] flex flex-col items-center text-center shadow-[0_0_20px_0_rgba(110,88,241,0.2)]">
                <div className="icon-container mb-4 flex items-center justify-center">
                    <img src={reinforcementlearning} alt="Reinforcement Learning Icon" className="w-[34px] h-[34px]" />
                </div>
                <h3 className="text-black text-[21px] leading-[46px] font-medium">Reinforcement Learning</h3>
                <p className="text-[16px] leading-[24px] text-[#242536] font-[400] w-[104%]   mt-3 ">
                    The capability to adapt and refine responses over time based on user feedback, improving relevancy.
                </p>
            </div>

            {/* card 2 */}
            <div className="bg-white font-inter pt-8 pb-8 pr-10 pl-10 rounded-[20px] flex flex-col items-center text-center shadow-[0_0_20px_0_rgba(110,88,241,0.2)]">
                <div className="icon-container mb-4 flex items-center justify-center">
                    <img src={artificialintelligence} alt="Machine Learning Icon" className="w-[34px] h-[34px]" />
                </div>
                <h3 className="text-black text-[21px] leading-[46px] font-medium">Machine Learning</h3>
                <p className="text-[16px] leading-[24px] text-[#242536] font-[400] w-[104%]   mt-3 ">
                    Learns to respond by analyzing human agent interactions, enhancing accuracy in replies.
                </p>
            </div>

            {/* card 3 */}

            <div className="bg-white font-inter pt-8 pb-8 pr-10 pl-10 rounded-[20px] flex flex-col items-center text-center shadow-[0_0_20px_0_rgba(110,88,241,0.2)]">
                <div className="icon-container mb-4 flex items-center justify-center">
                    <img src={search} alt="Intent Recognition Icon" className="w-[34px] h-[34px]" />
                </div>
                <h3 className="text-black text-[21px] leading-[46px] font-medium">Intent Recognition</h3>
                <p className="text-[16px] leading-[24px] text-[#242536] font-[400] w-[104%]   mt-3 ">
                    The ability to infer a user's meaning even when the phrasing is unfamiliar or unexpected.
                </p>
            </div>
            {/* card 4 */}
            <div className="bg-white font-inter pt-8 pb-8 pr-10 pl-10 rounded-[20px] flex flex-col items-center text-center shadow-[0_0_20px_0_rgba(110,88,241,0.2)]">
                <div className="icon-container mb-4 flex items-center justify-center">
                    <img src={nlp} alt="NLP Icon" className="w-[34px] h-[34px]" />
                </div>
                <h3 className="text-black text-[21px] leading-[26px] font-medium w-[120%]">Natural Language Processing (NLP)</h3>
                <p className="text-[16px] leading-[24px] text-[#242536] font-[400] w-[104%]   mt-3 ">
                    The skill to interpret and understand human language in text form.
                </p>
            </div>

            {/* card 5 */}

            <div className="bg-white font-inter pt-8 pb-8 pr-10 pl-10 rounded-[20px] flex flex-col items-center text-center shadow-[0_0_20px_0_rgba(110,88,241,0.2)]">
                <div className="icon-container mb-4 flex items-center justify-center">
                    <img src={socialnetwork} alt="Entity Recognition Icon" className="w-[34px] h-[34px]" />
                </div>
                <h3 className="text-black text-[21px] leading-[46px] font-medium">Entity Recognition</h3>
                <p className="text-[16px] leading-[24px] text-[#242536] font-[400] w-[104%]   mt-3 ">
                    The understanding that certain terms belong to broader categories (entities), such as recognizing "February 2" as a date.
                </p>
            </div>

            {/* card 6 */}
            <div className="bg-white font-inter pt-8 pb-8 pr-10 pl-10 rounded-[20px] flex flex-col items-center text-center shadow-[0_0_20px_0_rgba(110,88,241,0.2)]">
                <div className="icon-container mb-4 flex items-center justify-center">
                    <img src={listpaper} alt="Q&A Pairs Icon" className="w-[34px] h-[34px]" />
                </div>
                <h3 className="text-black text-[21px] leading-[46px] font-medium">Q&A Pairs</h3>
                <p className="text-[16px] leading-[24px] text-[#242536] font-[400] w-[104%]   mt-3 ">
                    Predefined responses used to provide factual information or solutions to user inquiries.
                </p>
            </div>
        </div>
    );
}

export default SolutionCard;
