import Hero from "../hero/hero"
import Services from "../chatbotservices"
import Solutions from "../solutions"
import Price from "../price"
import Demo from "../demo"
import ContactForm from "../contactform"
import { useEffect,useState } from "react"

const Main = ({ priceRef, contactRef }) => {
    const [activeSection, setActiveSection] = useState('');

    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
      };
    
      const handleScroll = () => {
        const pricePosition = priceRef.current?.getBoundingClientRect().top;
        const contactPosition = contactRef.current?.getBoundingClientRect().top;
        
        // Change active section based on scroll position
        if (pricePosition < window.innerHeight / 2 && pricePosition > -priceRef.current.offsetHeight) {
          setActiveSection('price');
        } else if (contactPosition < window.innerHeight / 2 && contactPosition > -contactRef.current.offsetHeight) {
          setActiveSection('contact');
        } else {
          setActiveSection('');
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      });
    return (
        <div className="font-inter">
            <Hero />
            <Services scrollToContact={scrollToContact} activesection={activeSection}/>
            <Solutions />
            <div ref={priceRef} >
                <Price scrollToContact={scrollToContact} activesection={activeSection} />
            </div>
            <Demo scrollToContact={scrollToContact} activesection={activeSection} />
            <div ref={contactRef}>
                <ContactForm />
            </div>
        </div>
    )

}

export default Main