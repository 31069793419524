
const Footer = () => {

    return(
        <div className="bg-[linear-gradient(101.84deg,#5C24FC_2.78%,#9D7AFF_98.95%)] pl-4 pr-4 pt-2 pb-2  ">
            <p className="text-center bg-[linear-gradient(180deg,#FFFFFF_0%,#FFFFFF_40%,#E9E6FD_100%)] bg-clip-text text-transparent text-[12px] text-opacity-[0.7%]">MagBot All rights reserved. © 2024</p>
        </div>
    )

}




export default Footer;
